'use client';

import { handleAPIReq, handleAPIReqV2, onboardingAPIV2Client } from '@/api';
import {
  type StatusLabel,
  providerLogoSizes,
  statusName,
} from '@/utils/constants';
import {
  CheckCircleIcon,
  EditIcon,
  QuestionIcon,
  ViewIcon,
  WarningIcon,
} from '@chakra-ui/icons';
import {
  Badge,
  Button,
  HStack,
  Image,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import {
  ONE_MINUTE_MS,
  addPonctuationToCNPJ,
  addPonctuationToCPF,
  uniq,
} from '@commons/js-utils';
import { useToasts } from '@medsimples/design-system';
import { MedicineBoxOutlined } from '@medsimples/design-system/src/antd-icons';
import {
  type Admin,
  ContractStatus,
  LegalEntityType,
  type ListSyncErrorsResponse,
  type MedicalFacilityCoordinator,
  MedicalFacilityCoordinatorStatus,
  MedicalFacilityStatus,
  type Profession,
  type ProfessionalListResponseData,
  ProfessionalStatus,
  ProfessionalSyncStatus,
  UF,
  WorkflowStatus,
} from '@medsimples/doctor-onboarding-openapi-v2';
import { type QueryClient, useQuery } from '@tanstack/react-query';
import { Pagination, Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import * as E from 'fp-ts/Either';
import { Poppins } from 'next/font/google';
import { useRouter, useSearchParams } from 'next/navigation';
import { tryCatch } from 'ramda';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { dateTimeToBR } from '../../../utils/date';
import { useAuth } from '../../providers/auth_provider_client';
import { useDesignTokens } from '../../providers/design_tokens_provider';
import useMedicalFacilityCoordinator from '../adhoc_hooks/useMedicalFacilityCoordinator';
import useUpsertPersonMedicalFacilities from '../adhoc_hooks/useUpsertPersonMedicalFacilities';
import SyncButton from '../buttons/sync_button';
import PersonMedicalFacilityModal from '../modals/person_medical_facility_modal';
import SyncProfessionalModal from '../modals/sync_professional_modal';
import SyncStatusModal from '../modals/sync_status_modal';

interface props {
  textSearch: string;
  tabStatus: StatusLabel[];
  admin: Admin;
  resetSearch: () => void;
  legalEntityType?: LegalEntityType;
}

interface TableParams {
  field?: string;
  order?: string;
  filters?: Record<string, FilterValue>;
}

const statusesAbleToContract = ['WAITING_SYNCHRONIZATION', 'ENABLED'];

const poppins = Poppins({
  weight: ['400', '500', '600'],
  subsets: ['latin'],
});

const filtersLabels = {
  crmUF: 'Estado',
  specialties: 'Especialidades',
  status: 'Status',
  providerIds: 'Origem',
  professionIds: 'Área de formação',
  registerUFs: 'UF Conselho',
  medicalFacilityIds: 'Unidades',
};

export const refreshProfessionalList = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({ queryKey: ['professionalList'] });
};

export function DoctorsTable({
  textSearch,
  tabStatus,
  admin,
  resetSearch,
  legalEntityType,
}: props) {
  const tokens = useDesignTokens();
  const auth = useAuth();
  const ffs = auth.featureFlags;
  const toast = useToasts();
  const searchParams = useSearchParams();
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const [isContractLoading, setContractLoading] = useState(false);
  const [isDetailsLoading, setDetailsLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({});
  const [isSyncModalOpen, setSyncModalOpen] = useState(false);
  const [syncModalDocId, setSyncModalDocId] = useState<string>(null);
  const [selectedProfessionalInfo, setSelectedProfessionalInfo] = useState<{
    name: string;
    personId: string;
  }>();
  const [personMedicalFacilityModalOpen, setPersonMedicalFacilityModalOpen] =
    useState(false);
  const [syncStatusModalOpen, setSyncStatusModalOpen] =
    useState<boolean>(false);
  const [syncErrorsData, setSyncErrorsData] =
    useState<ListSyncErrorsResponse>();

  const page = Number(searchParams.get('page')) || 0;
  const limit = Number(searchParams.get('limit')) || 10;

  const hasMedicalFacilitiesList = useMemo(() => {
    return (
      ffs.MEDICAL_FACILITY_ASSIGNMENT &&
      !!admin?.permissions['admin.listMedicalFacilities']?.length
    );
  }, [admin, ffs]);
  const hasMedicalFacilitiesCoordinatorsList = useMemo(() => {
    return (
      ffs.MEDICAL_FACILITY_ASSIGNMENT &&
      !!admin?.permissions[
        'admin.listMedicalFacilitiesByMedicalFacilityCoordinator'
      ]?.length
    );
  }, [admin, ffs]);
  const hasMedicalFacilitiesAssignments = useMemo(() => {
    return (
      ffs.MEDICAL_FACILITY_ASSIGNMENT &&
      !!admin?.permissions['admin.getPersonMedicalFacilityAssignments']?.length
    );
  }, [admin, ffs]);

  const { data: professions } = useQuery({
    placeholderData: [],
    queryKey: ['professions'],
    queryFn: async () => {
      const resp = await handleAPIReqV2(
        () => onboardingAPIV2Client.admin.adminListProfession(),
        {
          respOptions: {
            defaultErrorMsg:
              'Houve um problema ao buscar a lista de profissões',
          },
        },
      );

      if (E.isLeft(resp)) {
        throw Error(resp.left);
      }

      const professionsList = resp.right.data.map((pr) => ({
        id: pr.id as Profession,
        name: pr.displayName,
      }));

      return professionsList;
    },
  });

  const {
    data: { professionals, total },
    isFetching: isFetchingProfessionals,
    refetch: refetchProfessionals,
    isFetched: isProfessionalsFetched,
  } = useQuery({
    placeholderData: { professionals: [], total: 0 },
    queryKey: ['professionalList', searchParams.toString()],
    staleTime: 5 * ONE_MINUTE_MS,
    queryFn: async () => {
      const resp = await handleAPIReqV2(
        () =>
          onboardingAPIV2Client.admin.listProfessionals({
            search: searchParams.get('search'),
            sorting: {
              sort: searchParams.get('sort'),
              order: searchParams.get('order'),
            },
            filters: searchParams.get('filters')
              ? JSON.parse(searchParams.get('filters'))
              : {},
            pagination: {
              offset: page * limit,
              limit,
            },
            legalEntityType: legalEntityType ?? LegalEntityType.COMPANY,
          }),
        {
          respOptions: {
            defaultErrorMsg:
              'Houve um problema ao buscar a lista de profissionais, por favor tente novamente.',
          },
        },
      );

      if (E.isLeft(resp)) {
        console.log(resp);
        throw Error(resp.left);
      }

      return {
        professionals: resp.right.data,
        total: resp.right.metadata.pagination.total,
      };
    },
  });

  const medicalFacilitiesQuery = useQuery({
    placeholderData: [],
    queryKey: ['medicalFacilities', admin],
    queryFn: async () => {
      if (!hasMedicalFacilitiesList) return [];

      const resp = await handleAPIReqV2(
        () => onboardingAPIV2Client.admin.listMedicalFacilities({}),
        {
          respOptions: {
            defaultErrorMsg:
              'Houve um problema ao buscar a lista de unidades, por favor tente novamente.',
          },
        },
      );

      if (E.isLeft(resp)) {
        throw Error(resp.left);
      }

      return resp.right?.data?.list ?? [];
    },
  });

  const { data: specialties } = useQuery({
    queryKey: ['specialties'],
    placeholderData: [],
    queryFn: async () => {
      const resp = await handleAPIReqV2(
        () => onboardingAPIV2Client.admin.listDoctorsSpecialties(),
        {
          respOptions: {
            defaultErrorMsg: 'Houve um erro ao buscar especialidades',
          },
        },
      );

      if (E.isLeft(resp)) {
        throw Error(resp.left);
      }

      const specialties = (resp.right?.data ?? [])
        .filter(uniq('name'))
        .map((specialty) => ({
          text: specialty.name,
          value: specialty.name,
        }));

      return specialties;
    },
  });

  const { data: providers } = useQuery({
    queryKey: ['providers'],
    placeholderData: [],
    queryFn: async () => {
      const resp = await handleAPIReqV2(
        () => onboardingAPIV2Client.admin.listProviders(),
        {
          respOptions: {
            defaultErrorMsg: 'Houve um erro ao buscar origens',
          },
        },
      );

      if (E.isLeft(resp)) {
        throw Error(resp.left);
      }

      const providersList = resp.right.data;

      return providersList;
    },
  });

  const upsertPersonMedicalFacilitiesAssignment =
    useUpsertPersonMedicalFacilities();

  const medicalFacilityCoordinatorQuery = useMedicalFacilityCoordinator();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (isProfessionalsFetched) {
      const params = {
        search: textSearch,
        page: 0,
        sort: tableParams.field,
        order: tableParams.order,
        filters: JSON.stringify(tableParams.filters),
      };

      const updatedSearchParams = Object.entries(params).reduce(
        (params, [key, value]) => params + (value ? `&${key}=${value}` : ''),
        '?',
      );

      router.replace(updatedSearchParams);
    }
  }, [textSearch, tableParams, router]);

  useEffect(() => {
    setTableParams((current) => ({
      ...current,
      filters: {
        ...current.filters,
        status: tabStatus.length ? tabStatus : null,
      },
    }));
  }, [tabStatus]);

  const hasCompleteContract = (professionalId: string) => {
    const professional = professionals.find((p) => p.id === professionalId);
    return Boolean(
      professional?.contracts?.some(
        (c) => c.contractStatus === ContractStatus.COMPLETED,
      ),
    );
  };

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<ProfessionalListResponseData>,
  ) => {
    setTableParams({
      filters,
      field: String(sorter.field),
      order: sorter.order,
    });
  };

  const handlePageChange = (page: number, pageSize: number) => {
    const params = {
      page: page - 1,
      limit: pageSize,
      search: textSearch,
      sort: tableParams.field,
      order: tableParams.order,
      filters: JSON.stringify(tableParams.filters),
    };

    router.replace(
      Object.entries(params).reduce(
        (params, [key, value]) => params + (value ? `&${key}=${value}` : ''),
        '?',
      ),
    );
  };

  const resetFilters = () => {
    setTableParams({
      ...tableParams,
      filters: {},
    });
    resetSearch();
  };

  const redirectToDetails = (professionalId: string) => {
    setDetailsLoading(true);
    setIsLoading(true);
    ffs.ENABLE_PRE_APPROVAL
      ? router.push(`/pre-approval/professionals/${professionalId}`)
      : router.push(`/professionals/${professionalId}`);
  };

  const sendContract = (cnpj: string) => {
    setContractLoading(true);
    router.push(`/contract/elaborate?cnpj=${cnpj}`);
  };

  const onClickSyncProfessional = async (professionalId: string) => {
    setSyncModalOpen(true);
    setSyncModalDocId(professionalId);
  };

  const onCloseSyncModal = () => {
    setSyncModalOpen(false);
  };

  const onSync = (groupIds: string[]) => {
    syncProfessional(syncModalDocId, groupIds).finally(() =>
      setSyncModalOpen(false),
    );
  };

  const syncProfessional = async (
    professionalId: string,
    groupIds: string[],
  ) => {
    setIsLoading(true);
    setSyncModalOpen(false);
    const res = await handleAPIReq(() =>
      onboardingAPIV2Client.admin.syncProfessional({
        professionalId,
        shiftManagerGroupIds: groupIds,
      }),
    );
    if (E.isLeft(res)) {
      toast.errorToast(res.left);
      setIsLoading(false);
      return;
    }

    toast.successToast(
      'Profissional enviado para a ativação, em breve seu status será atualizado automaticamente.',
    );
    await refetchProfessionals();
    setIsLoading(false);
  };

  const buttonIcon = useCallback(
    (status: string, providerId: string) => {
      if (
        (status === ProfessionalStatus.WAITING_APPROVAL &&
          admin?.permissions?.['admin.gdmApproval']?.includes(providerId)) ||
        (status === ProfessionalStatus.WAITING_COMPLIANCE &&
          admin?.permissions?.['admin.complianceApproval']?.includes(
            providerId,
          ))
      ) {
        return <EditIcon />;
      }

      return <ViewIcon />;
    },
    [admin?.permissions],
  );

  const buttonLabel = useCallback(
    (status: string, providerId: string) => {
      if (
        (status === ProfessionalStatus.WAITING_APPROVAL &&
          admin?.permissions?.['admin.gdmApproval']?.includes(providerId)) ||
        (status === ProfessionalStatus.WAITING_COMPLIANCE &&
          admin?.permissions?.['admin.complianceApproval']?.includes(
            providerId,
          ))
      ) {
        return 'Analisar';
      }

      return 'Visualizar';
    },
    [admin?.permissions],
  );

  const syncProfessionalPermission = (providerId: string) =>
    admin?.permissions?.['admin.syncProfessional']?.includes(providerId);

  const assignMedicalFacilityPermission =
    (
      medicalFacilityCoordinatorQuery?.data as unknown as MedicalFacilityCoordinator
    )?.status === MedicalFacilityCoordinatorStatus.ACTIVE;

  const openSyncStatusModal = async (professionalId: string) => {
    setIsLoading(true);
    try {
      await handleAPIReq(() =>
        onboardingAPIV2Client.admin.listSyncErrors({ professionalId }),
      ).then((res) => {
        //TODO update after merge with medical facility assignment
        if (E.isLeft(res)) {
          toast.errorToast(
            res.left,
            'Erro na busca de dados sobre a ativação profissional',
          );
          return;
        }
        setSyncErrorsData({
          ...res.right.data,
        });
      });
    } finally {
      setIsLoading(false);
      setSyncStatusModalOpen(true);
    }
  };

  const columns: ColumnsType<ProfessionalListResponseData> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 80,
      sorter: true,
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'CPF',
      key: 'cpf',
      dataIndex: 'cpf',
      render: (_, record) =>
        tryCatch(addPonctuationToCPF, (e: Error, cpf) => {
          // sentry report?
          console.warn(e.message);
          return cpf;
        })(record.cpf),
      sorter: false,
    },
    ...(legalEntityType === LegalEntityType.COMPANY
      ? [
          {
            title: 'CNPJ',
            key: 'cnpj',
            dataIndex: 'cnpj',
            render: (_, record) =>
              tryCatch(addPonctuationToCNPJ, (e: Error, cnpj) => {
                // sentry report?
                console.error(e.message);
                return cnpj;
              })(record.cnpj),
            sorter: false,
          },
        ]
      : []),
    ...(legalEntityType === LegalEntityType.COMPANY &&
    ffs.AMIL_SAP_BP_INTEGRATION
      ? [
          {
            title: 'BP',
            key: 'businessPartner',
            dataIndex: 'businessPartner',
            sorter: false,
          },
        ]
      : []),
    {
      title: 'Área de formação',
      key: 'professionIds',
      dataIndex: 'profession',
      render: (_, record: ProfessionalListResponseData) =>
        record.professionDisplayName ?? 'Médico',
      sorter: false,
      filters: professions?.map((p) => ({
        text: p.name,
        value: p.id,
      })),
      filteredValue: tableParams.filters?.professionIds || null,
    },
    {
      title: 'Nº Conselho',
      key: 'register',
      dataIndex: 'register',
      render: (_, record: ProfessionalListResponseData) =>
        record.registerNumber,
      sorter: true,
    },
    {
      title: 'UF Conselho',
      key: 'registerUFs',
      dataIndex: 'registerUFs',
      filters: Object.values(UF).map((uf) => ({ text: uf, value: uf })),
      render: (_, record: ProfessionalListResponseData) => record.registerUF,
      filteredValue: tableParams.filters?.registerUFs || null,
    },
    {
      title: 'Especialidades',
      key: 'specialties',
      dataIndex: 'specialties',
      filters: specialties,
      filterSearch: true,
      render: (_, record: ProfessionalListResponseData) =>
        record.specialties?.map((specialty, index) =>
          renderDoctorSpecialties(index, specialty),
        ) ?? '',
      filteredValue: tableParams.filters?.specialties || null,
    },
    {
      title: 'Atualizado em',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      defaultSortOrder: 'descend',
      render: (_, { updatedAt }) => dateTimeToBR(new Date(updatedAt)),
      sorter: (a, b) =>
        new Date(a.updatedAt).getTime() > new Date(b.updatedAt).getTime()
          ? 1
          : -1,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      filters: [
        WorkflowStatus.ACTION_PENDING,
        // TODO: segregate typespec enum from logic; pre-fetch instead
        ...Object.values(ProfessionalStatus).filter(
          // ignore pre-approval status if feature is disabled
          (status) =>
            ffs.ENABLE_PRE_APPROVAL ||
            ![
              ProfessionalStatus.WAITING_PRE_APPROVAL,
              ProfessionalStatus.PRE_APPROVED,
            ].includes(status),
        ),
      ].map((status) => ({
        text: statusName[status],
        value: status,
      })),
      render: (_, record: ProfessionalListResponseData) => {
        return record.syncStatus === ProfessionalSyncStatus.PARTIALLY_CREATED &&
          record.status === ProfessionalStatus.ENABLED ? (
          <Tooltip
            key={record.id}
            label={
              'Profissional ativo com documentos faltantes e/ou ainda não atribuído à todas as unidades de saúde. Clique para detalhar'
            }
            fontSize='md'
            bg={tokens.background.light.color}
            color={tokens.text.gray.color}
            hasArrow
            borderRadius='md'
            p={2}
          >
            <Text
              onClick={() => openSyncStatusModal(record.id)}
              cursor='pointer'
            >
              {statusName[record.status]}
              <Text
                fontSize='0.8em'
                color='red'
                fontWeight='bold'
                marginBottom={1}
                marginLeft={1}
                display={'inline-block'}
              >
                *
              </Text>
            </Text>
          </Tooltip>
        ) : (
          <Text>{statusName[record.status]}</Text>
        );
      },
      filteredValue: tableParams.filters?.status || null,
    },
    {
      title: 'Unidades',
      hidden: !hasMedicalFacilitiesList,
      key: 'medicalFacilityIds',
      dataIndex: 'assignments',
      filters: medicalFacilitiesQuery?.data
        ? medicalFacilitiesQuery.data
            .filter((mf) => mf.status === MedicalFacilityStatus.ACTIVE)
            .map((mf) => ({
              text: mf.name,
              value: mf.id,
            }))
        : [],
      filteredValue: tableParams.filters?.medicalFacilityIds || null,
      filterSearch: true,
      render: (_, record) => {
        const maxVisibleNames = 2;
        const activeAssignments = record?.assignments?.filter(
          (a) =>
            medicalFacilitiesQuery?.data.find(
              (mf) => mf.id === String(a.medicalFacilityId),
            )?.status === MedicalFacilityStatus.ACTIVE,
        );
        return (
          <>
            {activeAssignments
              ?.map((a) => a.medicalFacilityName)
              .slice(0, maxVisibleNames)
              .map((name) => (
                <Text key={name}>• {name}</Text>
              ))}
            {activeAssignments?.map((a) => a.medicalFacilityName).length >
              maxVisibleNames && (
              <Text>
                +
                {activeAssignments?.map((a) => a.medicalFacilityName).length -
                  maxVisibleNames}
                ...
              </Text>
            )}
          </>
        );
      },
    },
    {
      title: 'Origem',
      key: 'providerIds',
      dataIndex: 'providerIds',
      render: (_, { providerId }) => {
        const provider = providers?.find((p) => p.id === providerId);
        return (
          <Image
            src={provider?.logo_uri}
            alt={provider?.display_name}
            maxH={providerLogoSizes[provider?.slug] ?? '30px'}
            marginLeft={'0.5em'}
          />
        );
      },
      filters: providers?.map((provider) => ({
        text: provider.display_name,
        value: provider.id,
      })),
      filteredValue: tableParams.filters?.providerIds || null,
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <VStack>
          {(!ffs.CONTRACT_MODULE ||
            legalEntityType === LegalEntityType.INDIVIDUAL ||
            hasCompleteContract(record.id)) &&
            syncProfessionalPermission(record.providerId) &&
            record.status === ProfessionalStatus.WAITING_SYNCHRONIZATION && (
              <SyncButton
                size='md'
                isDisabled={[
                  ProfessionalSyncStatus.UNABLE,
                  ProfessionalSyncStatus.SENT,
                ].includes(record.syncStatus)}
                onClick={() => onClickSyncProfessional(record.id)}
              />
            )}
          {statusesAbleToContract.includes(record.status) &&
            ffs.CONTRACT_MODULE &&
            legalEntityType === LegalEntityType.COMPANY && (
              <Button
                leftIcon={<EditIcon />}
                fontFamily={poppins.style.fontFamily}
                colorScheme={tokens.button.primary.scheme}
                variant='outline'
                size='md'
                style={{
                  width: '150px',
                  fontWeight: 300,
                  borderRadius: '7px',
                  boxShadow: 'none',
                  padding: '1rem',
                }}
                onClick={() => sendContract(record.cnpj)}
                isLoading={isContractLoading}
              >
                Contratar
              </Button>
            )}

          {Boolean(record.status !== ProfessionalStatus.REGISTERING) && (
            <Button
              leftIcon={buttonIcon(record.status, record.providerId)}
              fontFamily={poppins.style.fontFamily}
              colorScheme={tokens.button.primary.scheme}
              variant='outline'
              size='md'
              style={{
                width: '150px',
                fontWeight: 300,
                borderRadius: '7px',
                boxShadow: 'none',
                padding: '1rem',
              }}
              onClick={() => redirectToDetails(record.id)}
              isLoading={isDetailsLoading}
            >
              {buttonLabel(record.status, record.providerId)}
            </Button>
          )}

          {hasMedicalFacilitiesCoordinatorsList &&
            hasMedicalFacilitiesAssignments && (
              <Button
                leftIcon={<MedicineBoxOutlined />}
                fontFamily={poppins.style.fontFamily}
                colorScheme={tokens.button.primary.scheme}
                variant='outline'
                size='md'
                style={{
                  width: '150px',
                  fontWeight: 300,
                  borderRadius: '7px',
                  boxShadow: 'none',
                  padding: '1rem',
                }}
                onClick={() => {
                  setSelectedProfessionalInfo({
                    name: record?.name,
                    personId: record?.personId,
                  });
                  setPersonMedicalFacilityModalOpen(true);
                }}
                isLoading={isDetailsLoading}
              >
                Unidades
              </Button>
            )}
        </VStack>
      ),
    },
  ];

  const renderActiveFilters = () => {
    return Object.values(tableParams?.filters || {}).filter((value) => value)
      .length || searchParams.get('search') ? (
      <HStack justifyContent={'flex-end'} marginBottom={3}>
        <Text>Filtros ativos:</Text>
        {searchParams.get('search') ? (
          <Badge colorScheme='green'>Nome</Badge>
        ) : null}
        {Object.entries(tableParams?.filters || {}).map(([filter, value]) =>
          value ? (
            <Badge key={filter} colorScheme='green'>
              {filtersLabels[filter]}
            </Badge>
          ) : null,
        )}
        <Button
          marginLeft={3}
          size={'xs'}
          colorScheme={tokens.button.primary.scheme}
          onClick={resetFilters}
          variant={'link'}
          isDisabled={isLoading}
        >
          Limpar filtros
        </Button>
      </HStack>
    ) : null;
  };

  const renderDoctorSpecialties = (index, specialty) => {
    const iconData = {
      DENIED: { label: 'Negado', icon: <WarningIcon color='#db3737' /> },
      PENDING: { label: 'Pendente', icon: <QuestionIcon color='orange' /> },
      APPROVED: {
        label: 'Aprovado',
        icon: <CheckCircleIcon color='#4ebd26' />,
      },
    }[specialty.status] ?? {
      label: 'Aprovado pelo CFM',
      icon: <CheckCircleIcon color='#63B3ED' />,
    };

    return (
      <HStack key={index}>
        <Tooltip label={iconData.label} placement='top'>
          {iconData.icon}
        </Tooltip>
        <p>{specialty.specialty}</p>
      </HStack>
    );
  };

  return (
    <>
      {renderActiveFilters()}
      <Table
        virtual
        scroll={{ x: 2000 }}
        loading={isLoading || isFetchingProfessionals}
        rowKey={'id'}
        columns={columns}
        dataSource={professionals}
        onChange={handleTableChange}
        pagination={false}
      />
      {!(isLoading || isFetchingProfessionals) && (
        <HStack justifyContent={'center'} marginTop={4}>
          <Pagination
            showSizeChanger
            current={page + 1}
            pageSize={limit}
            onChange={handlePageChange}
            total={total}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} de ${total} médicos`
            }
          />
        </HStack>
      )}
      <SyncProfessionalModal
        isOpen={isSyncModalOpen}
        onClose={onCloseSyncModal}
        onSync={onSync}
      />
      {hasMedicalFacilitiesCoordinatorsList && (
        <PersonMedicalFacilityModal
          professionalName={selectedProfessionalInfo?.name}
          personId={selectedProfessionalInfo?.personId}
          userId={auth.user.id}
          isOpen={personMedicalFacilityModalOpen}
          loading={upsertPersonMedicalFacilitiesAssignment.isPending}
          closeModal={() => {
            setPersonMedicalFacilityModalOpen(false);
            refetchProfessionals();
          }}
          onSave={(params) =>
            upsertPersonMedicalFacilitiesAssignment.mutateAsync(params)
          }
          isCoordinator={assignMedicalFacilityPermission}
          coordinatorId={medicalFacilityCoordinatorQuery.data?.id}
        />
      )}
      <SyncStatusModal
        isOpen={syncStatusModalOpen}
        onClose={() => setSyncStatusModalOpen(false)}
        syncErrorsData={syncErrorsData}
      />
    </>
  );
}

export default function DoctorsTableSuspense(props: props) {
  return (
    <Suspense>
      <DoctorsTable {...props} />
    </Suspense>
  );
}
