import type { MedicalFacilityCoordinator } from '@medsimples/doctor-onboarding-openapi-v2';
import { useQuery } from '@tanstack/react-query';
import * as E from 'fp-ts/Either';
import { handleAPIReqV2, onboardingAPIV2Client } from '../../../api';
import { useAuth } from '../../providers/auth_provider_client';

const useMedicalFacilityCoordinator = () => {
  const auth = useAuth();
  const ffs = auth.featureFlags;
  return useQuery({
    queryKey: ['medicalFacilityCoordinator'],
    queryFn: async () => {
      if (
        !ffs.MEDICAL_FACILITY_ASSIGNMENT ||
        !auth.user.isMedicalFacilityCoordinator
      )
        return null;
      const resp = await handleAPIReqV2(
        () =>
          onboardingAPIV2Client.admin.medicalFacilityCoordinatorInfo({
            userId: auth.user.id,
          }),
        {
          respOptions: {
            defaultErrorMsg: 'Houve um erro ao buscar unidades do coordenador',
          },
        },
      );

      if (E.isLeft(resp)) {
        throw Error(resp.left);
      }

      return resp.right?.data ?? ({} as MedicalFacilityCoordinator);
    },
  });
};

export default useMedicalFacilityCoordinator;
