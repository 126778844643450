'use client';

import { useToasts } from '@medsimples/design-system';
import type { PersonMedicalFacilityAssignment } from '@medsimples/doctor-onboarding-openapi-v2';
import { useMutation } from '@tanstack/react-query';
import * as E from 'fp-ts/Either';
import { handleAPIReqV2, onboardingAPIV2Client } from '../../../api';

const useUpsertPersonMedicalFacilities = () => {
  const { errorToast, successToast } = useToasts();

  return useMutation({
    mutationKey: ['upsertPersonMedicalFacilitiesAssignment'],
    mutationFn: async ({
      personId,
      assignments,
    }: {
      personId: string;
      assignments: PersonMedicalFacilityAssignment[];
    }) => {
      const resp = await handleAPIReqV2(
        () =>
          onboardingAPIV2Client.admin.upsertPersonMedicalFacilityAssignments({
            personId,
            assignments,
          }),
        {
          respOptions: {
            defaultErrorMsg:
              'Houve um erro ao atualizar as unidades médicas deste profissional',
          },
        },
      );

      if (E.isLeft(resp)) {
        console.error(resp.left);
        errorToast(resp.left);
        throw resp.left;
      }

      successToast('Unidades médicas atualizadas com sucesso');
    },
  });
};

export default useUpsertPersonMedicalFacilities;
