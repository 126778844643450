import { ArrowBackIcon, CheckIcon } from '@chakra-ui/icons';
import {
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  VStack,
} from '@chakra-ui/react';
import type { MedicalFacility } from '@medsimples/doctor-onboarding-openapi-v2';
import type { PersonMedicalFacilityAssignment } from '@medsimples/doctor-onboarding-openapi/dist/client';
import { useQuery } from '@tanstack/react-query';
import { Empty } from 'antd';
import { useEffect, useState } from 'react';
import { onboardingAPIV2Client } from '../../../api';
import { useDesignTokens } from '../../providers/design_tokens_provider';

export interface TypeMedicalFacilityCoordinatorViewModal {
  id: string;
  name: string;
  email: string;
  cpf: string;
  status: string;
}

interface AssignmentObject {
  id: string;
  name: string;
  coordinatorId: string;
}

interface props {
  closeModal: () => void;
  onSave: (params: {
    personId: string;
    assignments: PersonMedicalFacilityAssignment[];
  }) => Promise<void>;
  isOpen: boolean;
  professionalName: string;
  personId: string;
  userId: string;
  loading: boolean;
  isCoordinator: boolean;
  coordinatorId?: string;
}

export default function PersonMedicalFacilityModal({
  closeModal,
  isOpen,
  onSave,
  professionalName,
  personId,
  userId,
  loading,
  isCoordinator,
  coordinatorId,
}: props) {
  const tokens = useDesignTokens();

  const [assignmentsList, setAssignmentsList] = useState<
    PersonMedicalFacilityAssignment[]
  >([]);
  const [medicalFacilityList, setMedicalFacilityList] = useState<
    MedicalFacility[]
  >([]);
  const [initializing, setInitializing] = useState(false);

  const medicalFacilityCoordinatorAssignmentsQuery = useQuery({
    placeholderData: [],
    queryKey: ['coordinatorUnits', userId],
    queryFn: async ({ queryKey: [, userId] }) => {
      if (!userId) return [];

      const resp =
        await onboardingAPIV2Client.admin.listMedicalFacilitiesByMedicalFacilityCoordinator(
          {
            userId,
          },
        );

      setMedicalFacilityList(resp?.data);
      return resp?.data;
    },
  });

  const personAssignmentsQuery = useQuery({
    placeholderData: {
      assignmentsList: [],
    },
    queryKey: ['personAssignments', personId, isOpen],
    queryFn: async () => {
      if (!isOpen)
        return {
          assignmentsList: [],
        };
      setInitializing(true);
      const resp =
        await onboardingAPIV2Client.admin.getPersonMedicalFacilityAssignments({
          personId,
        });

      setAssignmentsList(resp?.data?.assignments);
      setInitializing(false);
      return {
        assignmentsList: resp?.data?.assignments,
      };
    },
  });

  // act as onOpen/onClose since modal is kept rendered
  useEffect(() => {
    isOpen && setAssignmentsList(personAssignmentsQuery.data.assignmentsList);
    isOpen &&
      setMedicalFacilityList(medicalFacilityCoordinatorAssignmentsQuery.data);
  }, [
    personAssignmentsQuery?.data?.assignmentsList,
    medicalFacilityCoordinatorAssignmentsQuery?.data,
    isOpen,
  ]);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size={'xl'} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader maxW={'90%'}>
          Unidades médicas atribuídas para {professionalName}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6}>
            {isCoordinator && (
              <Select
                disabled={loading || initializing}
                value=''
                onChange={(e) => {
                  setAssignmentsList((list) => {
                    const medicalFacilityToAdd = medicalFacilityList.find(
                      (u) => u.id === e.target.value,
                    );
                    return [
                      ...list,
                      {
                        personId,
                        medicalFacilityId: medicalFacilityToAdd.id,
                        medicalFacilityName: medicalFacilityToAdd.name,
                        medicalFacilityCoordinatorId: coordinatorId,
                      },
                    ];
                  });
                }}
              >
                <option disabled value=''>
                  Selecione as unidades
                </option>
                {medicalFacilityList
                  .filter(
                    (mf) =>
                      !assignmentsList
                        .map((a) => a.medicalFacilityId)
                        .includes(mf.id),
                  )
                  .map((mf) => (
                    <option key={mf.id} value={mf.id}>
                      {mf.name}
                    </option>
                  ))}
              </Select>
            )}

            <Card w='100%' height='15rem' overflowY='scroll'>
              <CardBody>
                <VStack
                  spacing={3}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  {initializing ? (
                    <Center>
                      <Spinner />
                    </Center>
                  ) : (
                    <>
                      {assignmentsList.length === 0 ? (
                        <Empty
                          description={
                            'Não há unidades médicas atribuídas a este profissional'
                          }
                        />
                      ) : (
                        assignmentsList.map((a) => (
                          <Tag
                            key={a.medicalFacilityId}
                            width='full'
                            height='10'
                            colorScheme={tokens.button.primary.scheme}
                            borderRadius='6'
                          >
                            <Flex
                              w='100%'
                              justifyContent='space-between'
                              alignItems='center'
                            >
                              <TagLabel>
                                <Text fontSize={'md'}>
                                  {a.medicalFacilityName}
                                </Text>
                              </TagLabel>
                              <TagCloseButton
                                hidden={
                                  !isCoordinator ||
                                  a.medicalFacilityCoordinatorId !==
                                    coordinatorId
                                }
                                onClick={() =>
                                  setAssignmentsList((list) =>
                                    list.filter(
                                      (u) =>
                                        u.medicalFacilityId !==
                                        a.medicalFacilityId,
                                    ),
                                  )
                                }
                              />
                            </Flex>
                          </Tag>
                        ))
                      )}
                    </>
                  )}
                </VStack>
              </CardBody>
            </Card>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack w='100%' justifyContent='center'>
            <Button
              isDisabled={loading}
              colorScheme='red'
              variant='outline'
              leftIcon={<ArrowBackIcon />}
              onClick={closeModal}
            >
              Voltar
            </Button>
            <Button
              hidden={!isCoordinator}
              isDisabled={initializing}
              isLoading={loading}
              leftIcon={<CheckIcon />}
              colorScheme='green'
              marginRight={4}
              type='submit'
              onClick={async () => {
                await onSave({
                  personId,
                  assignments: assignmentsList,
                });
                personAssignmentsQuery.refetch();
                setTimeout(() => closeModal(), 1000);
              }}
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
